<template>
  <v-card :loading="isLoading">
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headers"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Sakin Mali İşlemleri"
            icon="mdi-home-account"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :edit-enabled="selectedItems.length === 1"
            @click:edit="handleEditClick"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          >
            <template slot="buttons:append">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="float-end"
                    color="pink"
                    text
                    icon
                    small
                    v-if="can('edit-house-income') && $refs.batchForm"
                    @click.prevent="
                      $refs.batchForm.show(
                        selectedItems.map((item) => item.id),
                        7
                      )
                    "
                    :disabled="selectedItems.length === 0"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon>mdi-email</v-icon>
                  </v-btn>
                </template>

                Bildirim Gönder
              </v-tooltip>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="float-end"
                    color="pink"
                    text
                    icon
                    small
                    v-if="can('edit-house-income')"
                    @click.prevent="
                      () =>
                        $refs.confirmSMS.show(
                          'Borçlu kişilere SMS gönderilecek. Emin misiniz?'
                        )
                    "
                    :disabled="selectedItems.length === 0"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon>mdi-message-text</v-icon>
                  </v-btn>
                </template>

                SMS Gönder
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="float-end"
                    color="pink"
                    text
                    icon
                    small
                    v-if="can('edit-house-income')"
                    @click.prevent="
                      () =>
                        $refs.confirmEmail.show(
                          'Borçlu kişilere e-posta gönderilecek. Emin misiniz?'
                        )
                    "
                    :disabled="selectedItems.length === 0"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon>mdi-email</v-icon>
                  </v-btn>
                </template>

                E-Posta Gönder
              </v-tooltip> -->
            </template>
            <template slot="buttons:prepend">
              <rs-select
                class="float-end me-2"
                style="max-width: 150px"
                label="Sakin Filtrele"
                :items="debtStatusList"
                v-model="search.debt_status"
                dense
              />
            </template>
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals :headers="headers" :totals="footTotals" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'incomes.residents.show',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.name="{ item, value }">
          <router-link
            :to="{
              name: can('edit-resident')
                ? 'definitions.residents.edit'
                : 'definitions.residents.show',
              params: { id: item.id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.door_number="{ item, value }">
          <router-link
            v-if="item.house_id && can('see-house-income')"
            :to="{
              name: 'incomes.houses.show',
              params: { id: item.house_id },
            }"
          >
            {{ value }}
          </router-link>
          <template v-else>{{ value }}</template>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.house_count="{ value }">
          <rs-table-cell-number :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.total_assessment="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.total_collecting="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.balance="{ value }">
          <rs-table-cell-balance colored :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.deferment_balance="{ value }">
          <rs-table-cell-balance :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.debt_balance="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.collecting_balance="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.phone="{ value }">
          <rs-table-cell-phone :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.email="{ value }">
          <rs-table-cell-email :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.block="{ item, value }">
          {{ value }}
        </template>
      </v-data-table>

      <rs-confirm
        ref="confirmSMS"
        :loading="isLoading"
        @confirmed="handleSendSMSSubmitClick"
        @cancelled="$refs.confirmSMS.hide()"
      />

      <rs-confirm
        ref="confirmEmail"
        :loading="isLoading"
        @confirmed="handleSendEmailSubmitClick"
        @cancelled="$refs.confirmEmail.hide()"
      />

      <AddResidentsToBatchForm ref="batchForm" />
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions, isPage } from "@/view/mixins";
import AddResidentsToBatchForm from "@/view/pages/communication/batch-notifications/AddResidentsToBatchForm";

export default {
  mixins: [filtersToURL, hasExport, hasPermissions, isPage],
  components: {
    AddResidentsToBatchForm,
  },
  computed: {
    ...mapGetters(["blocks", "cluster", "clusterId", "houseUserTypeList"]),
  },
  data() {
    return {
      pageMeta: {
        title: "Sakin Mali İşlemler Liste",
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Adı Soyadı",
          value: "name",
          searchable: "text",
          width: "200px",
        },
        {
          text: "BB adedi",
          value: "house_count",
          searchable: "number",
          width: "75px",
          align: "center",
        },
        {
          text: this.$t("labels.block"),
          value: "block",
          searchable: "multiselect",
          options: () => this.blocks,
          itemText: "name",
          width: "75px",
          align: "center",
        },
        {
          text: this.$t("labels.door_number_s"),
          value: "door_number",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: "Oturum Şekli",
          value: "house_user_type",
          searchable: "multiselect",
          options: () => this.houseUserTypeList,
        },
        {
          text: "Tahakkuk",
          value: "total_assessment",
          searchable: "number",
          align: "end",
        },
        {
          text: "Ödenen",
          value: "total_collecting",
          searchable: "number",
          align: "end",
        },
        {
          text: "Bakiye",
          value: "balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Gecikme Bakiye",
          value: "deferment_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Borç Bakiye",
          value: "debt_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Alacak Bakiye",
          value: "collecting_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: this.$t("labels.phone"),
          value: "phone",
          searchable: "text",
        },
        {
          text: this.$t("labels.status"),
          value: "is_active",
          searchable: "isActive",
        },
      ],
      search: {
        debt_status: null,
      },
      options: {
        sortBy: ["name"],
        sortDesc: [false],
      },
      debtStatusList: [
        {
          id: null,
          name: "Tümü",
        },
        {
          id: "both",
          name: "Hem Alacaklı ve Hem Borçlular",
        },
        {
          id: "negative",
          name: "Yalnızca Borçlular",
        },
        {
          id: "positive",
          name: "Yalnızca Alacaklılar",
        },
      ],
      titleBarAttrs: {
        exportUrl: "income/residents",
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = this.clusterId;

      return params;
    },
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query("income/residents", { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length !== 1) {
        return false;
      }

      this.$router.push({
        name: "incomes.residents.show",
        params: {
          id: this.selectedItems[0].id,
        },
      });
    },
    handleSendSMSSubmitClick() {
      this.$refs.confirmSMS.hide();
    },
    handleSendEmailSubmitClick() {
      this.$refs.confirmEmail.hide();
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
};
</script>
